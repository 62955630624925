import React, { FunctionComponent } from 'react';
import styles from '../../styles/SectionSecondaryContent.module.scss';
import Link from 'next/link';
import Image from 'next/image';
import { ArticleModel } from '../../services/content/article/models/ArticleModel';
import { VideoModel } from '../../services/content/video/models/VideoModel';
import { CategoryModel } from '../../services/content/category/models/CategoryModel';
import { getMainMediaImageByCropRatio } from '../../services/content/image/ImageConfiguration';
import { ImageRatios } from '../../services/content/image/models/ImageModel';
import { Routes } from '../../constants/Routes';
import { hasAuthors } from '../../constants/GlobalHelpers';
import { PlayButtonImage } from '../Partials/PlayButton/PlayButton';
import { ArticlePartialModel } from '../../services/content/article/models/ArticlePartialModel';

type ContentProps = {
    content: ArticleModel | VideoModel | ArticlePartialModel | null;
    category: CategoryModel | null | undefined;
    className?: string;
    isVideo?: boolean;
    lazyLoadImage?: boolean;
};

export const SectionSecondaryContent: FunctionComponent<ContentProps> = ({ content, className, lazyLoadImage }) => {
    if (content) {
        content = content as ArticleModel;
        const category = content?.category;
        const image: string = getMainMediaImageByCropRatio(content.mainMedia, ImageRatios.RATIO_4X3);
        return (
            <div className={`${className ? className : ''} pt-3 pb-3 pt-md-0 pb-md-0`}>
                <div className={`d-flex flex-column align-items-center`}>
                    <a href={Routes.openArticle(content.id)} className='w-100 mb-2 position-relative'>
                        <PlayButtonImage content={content} className={`${styles.pButton}`} />
                        <img
                            role='button'
                            className={`${styles.leadingImage} mb-1 mb-md-1`}
                            src={image ? image : '/placeholder_main_image.png'}
                            alt={content.title}
                            sizes='(max-width: 768px) 100vw, (max-width: 1920px) 50vw, 25vw'
                        />
                    </a>
                    <div
                        className={`w-100 d-flex flex-row align-items-end align-items-md-start align-items-lg-start justify-content-between mb-3 mb-md-3`}>
                        <a
                            className={`${styles.categoryText} font-lato-400`}
                            href={Routes.openCategory(category && category.seo ? category.seo.slug : '#')}>
                            {category?.title.toUpperCase()}
                        </a>
                        <div className='d-flex flex-row'>
                            {hasAuthors(content.authors) ? (
                                <div className={`${styles.author} font-lato-400`}>
                                    <span className='author-label'>Автор</span> {`${content.authors[0].name}`}
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                    <a
                        className={`${styles.contentTitle} font-playfair-400 w-100 text-start text-decoration-none`}
                        href={Routes.openArticle(content.id)}>
                        {content.title}
                    </a>
                </div>
            </div>
        );
    }

    return <></>;
};
