import React, { FunctionComponent } from 'react';
import styles from '../../styles/SectionLeadingContent.module.scss';
import Link from 'next/link';
import Image from 'next/image';
import { ArticleModel } from '../../services/content/article/models/ArticleModel';
import { VideoModel } from '../../services/content/video/models/VideoModel';
import { CategoryModel } from '../../services/content/category/models/CategoryModel';
import { getMainMediaImageByCropRatio } from '../../services/content/image/ImageConfiguration';
import { ImageRatios } from '../../services/content/image/models/ImageModel';
import { Routes } from '../../constants/Routes';
import { hasAuthors } from '../../constants/GlobalHelpers';
import { PlayButtonImage } from '../Partials/PlayButton/PlayButton';
import { ArticlePartialModel } from '../../services/content/article/models/ArticlePartialModel';

type ContentProps = {
    content: ArticleModel | VideoModel | ArticlePartialModel | null;
    category: CategoryModel | null | undefined;
    lazyLoadImage?: boolean;
};

export const SectionLeadingContent: FunctionComponent<ContentProps> = ({ content, category, lazyLoadImage }) => {
    if (content) {
        content = content as ArticleModel;
        const image: string = getMainMediaImageByCropRatio(content.mainMedia, ImageRatios.RATIO_4X3);
        return (
            <div className={`d-flex flex-column align-items-center w-100`}>
                <a href={Routes.openArticle(content.id)} className='position-relative mb-2 mb-md-3 mb-lg-3'>
                    <PlayButtonImage content={content} className={`${styles.pButton}`} />
                    <img
                        className={`normalize-nextjs-image mb-2 mb-md-3 w-100`}
                        src={image ? image : '/placeholder_main_image.png'}
                        alt={content.title}
                        sizes='(max-width: 768px) 100vw, (max-width: 1920px) 50vw, 33vw'
                    />
                </a>
                {category !== null && category?.title ? (
                    category?.seo?.slug ? (
                        <a
                            className={`${styles.categoryText} font-lato-700 mb-2 mt-2 mb-md-2`}
                            href={Routes.openCategory(category.seo.slug)}>
                            {category.title.toUpperCase()}
                        </a>
                    ) : (
                        <span className={`${styles.categoryText} font-lato-700 mb-2 mt-2 mb-md-2`}>
                            {category.title.toUpperCase()}
                        </span>
                    )
                ) : (
                    <></>
                )}
                <div className='mb-3 d-flex flex-row'>
                    {hasAuthors(content.authors) ? (
                        <div className={`${styles.author} font-lato-700`}>
                            <span className='author-label'>Автор</span> {`${content.authors[0].name}`}
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
                <a
                    href={Routes.openArticle(content.id)}
                    className={`${styles.contentTitle} font-playfair-700 px-3 px-md-5 text-center text-decoration-none`}>
                    {content.title}
                </a>
            </div>
        );
    }

    return <></>;
};
