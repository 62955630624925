import { ListModel } from '../../services/content/list/models/ListModel';

/**
 * If the section has a category assigned it returns the category title, if there is no category assigned, the list title will be displayed
 * @param section
 */
export const getDividerTitle = (section: ListModel) => {
    if (section.configuration?.category && section.configuration.category.title) {
        return section.configuration.category.title;
    } else {
        return section.title;
    }
};
