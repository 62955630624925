import React, { FunctionComponent, PropsWithChildren, useContext } from 'react';
import { getDividerTitle } from '../Section.helper';
import { ListModel } from '../../../services/content/list/models/ListModel';
import { SectionsDivider } from '../SectionsDivider';
import { SectionLeadingContent } from '../SectionLeadingContent';
import { SectionSecondaryContent } from '../SectionSecondaryContent';
import { SectionContext } from '../../../contexts/SectionContext';
import { Routes } from '../../../constants/Routes';
import { GoogleAdDesktop } from '../../Ads/GoogleAdDesktop';
import { adsLeadingNews } from '../../../services/ads/constants';
import { ArticleModel } from '../../../services/content/article/models/ArticleModel';
import { VideoModel } from '../../../services/content/video/models/VideoModel';
import { AdBackground } from '../../Ads/AdBackground';
import { GoogleAdMobile } from '../../Ads/GoogleAdMobile';
import styles from '../../../styles/NewsSection.module.scss';

type SectionProps = {
    section: ListModel | null | undefined;
    lazyLoadImage?: boolean;
};

export const LeadingNewsSectionTemplate: FunctionComponent<SectionProps> = ({ section, lazyLoadImage }) => {
    const sectionContext = useContext(SectionContext);
    const pageIndex = sectionContext.pageIndex;
    const displayDivider = pageIndex > 0;

    if (section && section.items) {
        const leadingContent = section.items[0];
        const secondaryContent = section.items.filter((item, idx) => idx > 0);
        const dividerTitle = getDividerTitle(section);

        return (
            <div className={`container mb-0 mb-md-5 w-100 ${styles.newsContainer}`}>
                <SectionsDivider
                    title={dividerTitle}
                    display={displayDivider}
                    link={Routes.openCategory(section?.configuration?.category?.seo?.slug)}
                />
                <div className={`d-flex flex-row py-3 justify-content-center py-md-0 mb-0 mb-md-5 w-100`}>
                    <SectionLeadingContent
                        content={leadingContent}
                        lazyLoadImage={lazyLoadImage ? lazyLoadImage : false}
                        category={
                            section.configuration?.category && section.configuration?.category.id
                                ? section.configuration?.category
                                : leadingContent?.category
                        }
                    />
                    <div className={'d-none d-md-inline-block d-lg-inline-block mx-2 col'}>
                        <AdBackground className='sticky-ad ad-300'>
                            <GoogleAdDesktop ad={adsLeadingNews[0]} />
                        </AdBackground>
                    </div>
                </div>
                <div className={`row px-0 mx-0 justify-content-center`}>
                    {secondaryContent.map((content, idx) => {
                        content = content as ArticleModel | VideoModel;
                        return (
                            <SecondaryNewsMobileWrapper key={`secondary-news-key-${content?.id}`} idx={idx}>
                                <SectionSecondaryContent
                                    lazyLoadImage={lazyLoadImage ? lazyLoadImage : false}
                                    className='col-12 col-md-3 mb-md-3 font-lato-700'
                                    content={content}
                                    category={
                                        section.configuration?.category && section.configuration?.category.id
                                            ? section.configuration?.category
                                            : content?.category
                                    }
                                />
                            </SecondaryNewsMobileWrapper>
                        );
                    })}
                </div>
                <div className='d-none d-md-flex d-lg-flex flex-row justify-content-center w-100'>
                    <AdBackground className='mb-0 mb-md-4 mb-lg-4'>
                        <GoogleAdDesktop ad={adsLeadingNews[1]} />
                    </AdBackground>
                </div>
            </div>
        );
    }

    return null;
};

const SecondaryNewsMobileWrapper: FunctionComponent<PropsWithChildren<{ idx: number }>> = ({ children, idx }) => {
    const SECOND_ARTICLE = 0;
    const FOURTH_ARTICLE = 3;

    if (idx === SECOND_ARTICLE) {
        return (
            <>
                {children}
                <div className={`d-inline-block d-md-none d-lg-none text-center ad-container-h600`}>
                    <AdBackground>
                        <GoogleAdMobile ad={adsLeadingNews[0]} className='sticky-ad' />
                    </AdBackground>
                </div>
            </>
        );
    }

    if (idx === FOURTH_ARTICLE) {
        return (
            <>
                {children}
                <div className={`d-inline-block d-md-none d-lg-none text-center ad-container-h600`}>
                    <AdBackground>
                        <GoogleAdMobile ad={adsLeadingNews[1]} className='sticky-ad' />
                    </AdBackground>
                </div>
            </>
        );
    }

    return <>{children}</>;
};
