import React, { FunctionComponent, ReactElement } from 'react';
import styles from '../../styles/SectionsDivider.module.scss';
import Link from 'next/link';

type Props = {
    title: string;
    display: boolean | 'displayIgnoringDeviceSize';
    link?: string;
    imageComponent?: ReactElement;
};

export const SectionsDivider: FunctionComponent<Props> = ({ title, display, link, imageComponent }) => {
    let displayClassName = getDisplayClassname(display);

    return (
        <div className={displayClassName}>
            <hr className={`${styles.line}`}></hr>
            <div
                className={`${styles.title} font-lato-400 text-center d-flex flex-row justify-content-center align-items-center`}>
                {link && link !== '/null' ? (
                    <div className='d-flex flex-column flex-md-row align-items-center'>
                        {imageComponent ?? imageComponent}
                        <Link href={link} className='text-decoration-none text-black'>
                            {title}
                        </Link>
                    </div>
                ) : (
                    <>
                        {imageComponent ?? imageComponent}
                        {title}
                    </>
                )}
            </div>
            <hr className={`${styles.line}`}></hr>
        </div>
    );
};

const getDisplayClassname = (display: boolean | 'displayIgnoringDeviceSize' | undefined) => {
    switch (display) {
        case false:
            return 'd-none';
        default:
            return '';
    }
};
