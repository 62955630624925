import { FunctionComponent } from 'react';
import Image from 'next/image';
import { getLeadingMediaOfContent } from '../../../services/content/mainMedia/MainMedia';
import { ArticleModel } from '../../../services/content/article/models/ArticleModel';
import { VideoFile } from '../../../services/content/video/models/VideoModel';
import { ResourceTypes } from '../../../services/content/mainMedia/normalizers/MainMediaNormalizer';
import { ArticlePartialModel } from '../../../services/content/article/models/ArticlePartialModel';

type Props = {
    content: ArticleModel | ArticlePartialModel;
    className?: string;
};

export const PlayButtonImage: FunctionComponent<Props> = ({ content, className }) => {
    const leadingMedia = getLeadingMediaOfContent(content.mainMedia);

    if (leadingMedia.resourceType === ResourceTypes.VIDEO || leadingMedia.resourceType === ResourceTypes.EMBED) {
        return (
            <div className={`${className ? className : ''}`}>
                <Image
                    fill
                    className={`normalize-nextjs-image`}
                    src={'/play_button.svg'}
                    alt={'video-play-button-image'}
                />
            </div>
        );
    }

    return null;
};
