import { ImageModel } from '../services/content/image/models/ImageModel';
import { AuthorModel } from '../services/content/author/models/AuthorModel';

export const hasOrigin = (image: ImageModel) => {
    return image && image.origin;
};

export const hasAuthors = (authors?: AuthorModel[], customAuthor?: string) => {
    const hasAuthor = authors && authors.length > 0 && authors.filter((author) => author.active).length > 0;

    let hasCustomAuthor = false;

    if (customAuthor) {
        hasCustomAuthor = customAuthor.length > 0;
    }

    return hasAuthor || hasCustomAuthor;
};

export const hasCustomAuthors = (customAuthors?: string) => {
    return customAuthors && customAuthors.length > 0;
};
