import { FunctionComponent, PropsWithChildren } from 'react';

type Props = {
    className?: string;
};
export const AdBackground: FunctionComponent<PropsWithChildren<Props>> = ({ children, className }) => {
    return (
        <div className={`pb-3 ${className ? className : ''}`}>
            <div>{children}</div>
        </div>
    );
};
